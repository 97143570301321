import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
		token: "",
		isLoggedIn: false,
		shift:0,
		username: "",
		//idemployee
		// nik:"",
	},
	outlet: {
		hkeep : "HKEEP",
	},
    isLoading: true,
	display:'none',
    loading: false,
    loading_submit: false,
    snackbar: false,
    error: false,
	text: '',
	drawer: null,
	mini: false,
	hover:false,
	dialog: false,
	dialog2: false,
    success: true,
	user_variabels: [],
	isDataTableLoading:false,
	detailMaintenance: {},
	overlay: false,
	avatar: '',
	profile: null,
	entities: [],
	absencePermitIsLoggedIn: false,
	kmb: {
		group_id: ''
	},
	sr: {
		group_id: ''
	},
	sm: {
		group_id: ''
	},
	kka: {
		group_id: ''
	},
	gbrk: {
		group_id: ''
	},
	kbt: {
		group_id: ''
	},

	/* MASTER MAINTENANCE */
	jsonData:{
		master:{
			millsData:{
				mill_id:'',
				mill_name:'',
				mill_code:'',
				address_1:'',
				address_2:'',
				city:'',
				phone_num:'',
				active_flag:'',
				fax_num:'',
				region:'',
				dt_modified:''
			},
			machineType:{},
			machineNames:{},
			maintenanceCategoryData:{},
			maintenanceEmployeeData:{},
		},
		maintenance:{
			unscheduledMaintenanceHeaderCreate:{},
			unscheduledMaintenanceHeaderMaintain:{},
			unscheduledMaintenanceHeaderClose:{},
			unscheduledMaintenanceActionGrid:{
				data:{},
			},
			unscheduledMaintenanceInspectGrid:{
				data:{},
			},
		}
	},

	/* FORM SHARED DATA */
	// MASTER
	masterMachineCategory:{
		machCategId:'',
		description:'',
		switchActive:true
	},
	masterMills:{
		// TODO belum dipakai semua
		dialogVisible:false,
		isProgress:false,
		switchActive:true,
		page:1, 
		itemPerPage:10,
		data:{}
	},
	masterMachineType:{
		// TODO belum dipakai semua
		dialogVisible:false,
		isProgress:false,
		switchActive:true,
		page:1, 
		itemPerPage:10,
		data:{}
	},
	masterMaintenanceCategory:{
		dialogVisible:false,
		isProgress:false,
		page:1, 
		itemPerPage:10,
		data:{
			category_id:'',
			description:'',
			isActive:true
		}
	},
	masterMaintenanceEmployee:{
		dialogVisible:false,
		isProgress:false,
		page:1, 
		itemPerPage:10,
		data:{
			nik:'',
			category:'',
			nama:'',
			isActive:true
		}
	},
	// Unscheduled Maintenance
	unscheduleMaintenanceDialogState:{
		tabOneNotEditable:Boolean,
		tabOneDisabled:Boolean,
		tabTwoNotEditable:Boolean,
		tabTwoDisabled:Boolean,
		tabThreeDisabled:Boolean,
		tabThreeNotEditable:Boolean,
		tabFourDisabled:Boolean,
		tabFourNotEditable:Boolean,
	},
	unscheduledMaintenanceCreate:{
		dialogVisible:false,
		isProgress:false,
		data:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			category_maintenance : "",
			status : 1,
			description : "",
			req_sparepart : "",
			request_date : "",
			start_date : "",
			finish_date : "",
			closed_date : "",
			requested_by : "",
			closed_by : "",
			created_by : "",
			page : 1,
			itemPerPage : 10,
		},
		passingData:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			category_maintenance : "",
			status : 1,
			description : "",
			req_sparepart : "",
			request_date : new Date(),
			start_date : "",
			finish_date : "",
			closed_date : "",
			requested_by : "",
			closed_by : "",
			created_by : "",
		}
	},
	unscheduledMaintenanceMaintain:{
		dialogVisible:false,
		isProgress:false,
		data:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			category_maintenance : "",
			status : 1,
			description : "",
			req_sparepart : "",
			request_date : "",
			start_date : "",
			finish_date : "",
			closed_date : "",
			requested_by : "",
			closed_by : "",
			created_by : "",
			page : 1,
			itemPerPage : 10,
		},
		passingData:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			category_maintenance : "",
			status : 1,
			description : "",
			req_sparepart : "",
			request_date : new Date(),
			start_date : "",
			finish_date : "",
			closed_date : "",
			requested_by : "",
			closed_by : "",
			created_by : "",
		}
	},
	unscheduledMaintenanceActionGrid:{
		dialogVisible:false,
		isProgress:false,
		filterData:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			wr_action_no: "",
			action_type: "",
			status_action : "",
			shift:"",
			//idemployee:"",
			require_sparepart : "",
			observation: "",
			correction : "",
			remark_action : "",
			remark_inspection : "",
			start_date : new Date(),
			finish_date : new Date(),
			inspect_date : new Date(),
			duration: "",
			loss_time: "",
			downtime: "",
			action_by : "",
			inspected_by : "",
			created_by : "",
			modified_by : "",
			page : 1,
			itemsPerPage : 10,
		},
		passingData:{
			mill_id : "",
			mach_type : "",
			mach_id : "",
			no_wr : "",
			wr_action_no: "",
			action_type: "",
			status_action : "",
			shift:"",
			idemployee:"",
			require_sparepart : "",
			observation: "",
			correction : "",
			remark_action : "",
			remark_inspection : "",
			start_date : new Date(),
			finish_date : new Date(),
			inspect_date : new Date(),
			duration: "",
			loss_time: "",
			downtime: "",
			action_by : "",
			inspected_by : "",
			created_by : "",
			modified_by : "",
			page : 1,
			itemsPerPage : 10,
		},
	},
	unscheduleMaintenancePK:{
		mill_id : "",
		mach_type : "",
		mach_id : "",
		no_wr : "",
		wr_action_no: "",
		request_date: "",
		category_id:"",
		page:1,
		itemsPerPage:10,
		status:1,
	},
	spareparReqForm:{
		dialogVisible:Boolean,
		isProgress:Boolean,
		page:1,
		itemPerPage:10,
	}
  },
  mutations: {
    USER_TOKEN (state, payload) {
		state.user.token = payload.access_token
		state.user.username = payload.username
		state.user.idemployee = payload.idemployee
		// state.user.nik = payload.idemployee
		state.user.shift = payload.shift
		state.user.isLoggedIn = true
	},
    USER_LOGOUT (state, payload) {
		if(payload == true) {
			state.user.token = ""
			state.user.isLoggedIn = false
			state.user.username = ""
			state.user.idemployee = ""
			// state.user.nik = ""
			state.entities = []
		}
	},
	SET_DRAWER_ADMIN (state) {
		if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			state.drawer = !state.drawer
		} else{
			state.drawer = true
			state.mini = !state.mini
		}
	},
	USER_VAR (state, payload) {
		state.user_variabels = payload
	},
	USER_GROUP_KMB (state, payload) {
		state.user_group_kmb = payload
	},

	USER_SALES (state, payload) {
		state.user_variabels = payload
	},

	USER_MILL_KKA (state, payload) {
		state.user_variabels = payload
	},

	/* MASTER MAINTENANCE */
	MILLS_DATA (state, payload) {
		state.jsonData.master.millsData = payload
	},
	MACHINE_TYPE_DATA (state, payload) {
		state.jsonData.master.machineType = payload
	},
	MACHINE_NAME_DATA (state, payload) {
		state.jsonData.master.machineNames = payload
	},
	MASTER_MAINTENANCE_CATEGORY_DATA (state, payload) {
		state.jsonData.master.maintenanceCategoryData = payload
	},
	MASTER_MAINTENANCE_EMPLOYEE_DATA (state, payload) {
		state.jsonData.master.maintenanceEmployeeData = payload
	},
	UNSCHEDULE_MAINTENANCE_CREATE (state, payload) {
		state.jsonData.maintenance.unscheduledMaintenanceHeaderCreate = payload
	},
	UNSCHEDULE_MAINTENANCE_MAINTAIN (state, payload) {
		state.jsonData.maintenance.unscheduledMaintenanceHeaderMaintain = payload
	},
	UNSCHEDULE_MAINTENANCE_CLOSE (state, payload) {
		state.jsonData.maintenance.unscheduledMaintenanceHeaderClose = payload
	},
	UNSCHEDULE_MAINTENANCE_ACTION_CREATE(state, payload){
		state.jsonData.maintenance.dialog.unscheduledMaintenanceActionGrid = payload
	},
	UNSCHEDULE_MAINTENANCE_ACTION_INSPECT(state, payload){
		state.jsonData.maintenance.dialog.unscheduledMaintenanceInspectGrid = payload
	},
	SET_DETAIL_MAINTENANCE_ACTION(state, payload){
		state.detailMaintenance = payload
	},
	SET_OVERLAY(state, payload){
		state.overlay = payload
	},
	getAvatar(state, avatar){
		state.avatar = avatar.avatar
		state.profile = avatar
	},
	USER_ENTITY(state, payload){
		state.entities = payload
	},
	SET_GROUP_KMB(state, payload){
		state.kmb.group_id = payload
	},
	SET_GROUP_SR(state, payload){
		state.sr.group_id = payload
	},
	SET_GROUP_SM(state, payload){
		state.sm.group_id = payload
	},
	SET_GROUP_KKA(state, payload){
		state.kka.group_id = payload
	},
	SET_GROUP_GBRK(state, payload){
		state.gbrk.group_id = payload
	},
	SET_GROUP_KBT(state, payload){
		state.kbt.group_id = payload
	},
	set_AbsencePermitIsLoggedIn(state, payload){
		state.absencePermitIsLoggedIn = payload
	},
  },
  actions: {
	setOverlay({ commit }, data){
		commit('SET_OVERLAY', data)
	},
	setAbsencePermitIsLoggedIn({ commit }, data){
		commit('set_AbsencePermitIsLoggedIn', data)
	},
	getDetailMaintainanceAction({ commit }, data){
		commit('SET_DETAIL_MAINTENANCE_ACTION', data)
	},
    loginUser({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.post(`${process.env.VUE_APP_URL}/api/login`, data)
			.then(res => {
				const data = res.data.data
				// console.log(data)
				axios.defaults.headers.common["Authorization"] = "Bearer " + data.access_token
				commit("USER_TOKEN", data);
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
    logoutUser({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/logout`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				delete axios.defaults.headers.common['Authorization']
				commit('USER_LOGOUT', true)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getVariabelUser({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/get_env_conf?entity_id=${data.data.entity_id}&appl_id=${data.data.appl_id}`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('USER_VAR', res.data.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	loginViva({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.post(`${process.env.VUE_APP_URL}/api/viva/forecast/envConfUser`,)
			.then(res => {
				const data = res.data.data
				console.log(data)
				axios.defaults.headers.common["Authorization"] = "Bearer " + data.access_token
				commit("USER_TOKEN", data);
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	// loginViva({ commit }, data) {
	// 	return new Promise((resolve, reject) => {
	// 		axios.get(`${process.env.VUE_APP_URL}/api/viva/forecast/envConfUser?user_id=${data.user_id}`, { 
	// 			headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
	// 		})
	// 		.then(res => {
	// 			commit('USER_SALES', res.data.result)
	// 			resolve(res)
	// 		})
	// 		.catch(err => {
	// 			reject(err)
	// 		})
	// 	})
	// },

	getEntityUser({ commit }, data) {
		console.log(data);
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/get_entity?entity_id=${data ? data : ''}`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('USER_ENTITY', res.data.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	getSalesIDKKA({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/kka/getSalesIDKKA?user_id=${data.user_id}`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('USER_SALES', res.data.result)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	getMillKKA({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/kka/getMillKKA?user_id=${data.user_id}`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('USER_MILL_KKA', res.data.result)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	getUserGroupKMB({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/kmb/getUserGroupKMB`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('USER_GROUP_KMB', res.data.result)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	getHeaderAvatar ({ commit }) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/profile`, { 
				headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			})
			.then(res => {
				commit('getAvatar', res.data.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	
	/* MASTER MAINTENANCE */
	getMasterMills({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/master/kka/mill?mill_id=${data}`,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			// axios.get(`${process.env.VUE_APP_URL}/api/get_env_conf?entity_id=${data.data.entity_id}&appl_id=${data.data.appl_id}`, { 
			// 	headers: { Authorization: `Bearer ${this.getters.isTokenUser}` } 
			// })

			.then(res => {
				commit('MILLS_DATA', res.data.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getMachineType({ commit }, data) {
		return new Promise((resolve, reject) => {
			var url = `${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_type?mill_id=${data.mill_id}`;
			axios.get(url,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				commit('MACHINE_TYPE_DATA', res.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getMachineName({ commit }, data) {
		console.log("store getMachineName");
		console.log(data);
		return new Promise((resolve, reject) => {
			var url = `${process.env.VUE_APP_URL}/api/master/kka/equipment/machine_id?mill_id=${data.mill_id}&mach_type=${data.mach_type}`;
			// console.log(url);
			axios.get(url,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				commit('MACHINE_NAME_DATA', res.data)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getMasterMaintenanceCategory({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/master/kka/maintenance_category?category_id=${data.category_id}&description=${data.description}&is_active=${data.is_active}&page=${data.page > 1 ? data.page : ''}&pagination=${data.item_per_page}`,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				commit('MASTER_MAINTENANCE_CATEGORY_DATA', res)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getMasterMaintenanceEmployee({ commit }, data) {
		return new Promise((resolve, reject) => {
			axios.get(`${process.env.VUE_APP_URL}/api/master/kka/master_employee?nik=${data.nik ? data.nik : ''}&category=${data.category ? data.category : ''}&nama=${data.nama ? data.nama : ''}&is_active=${data.is_active ? data.is_active : ''}&page=${data.page > 1 ? data.page : ''}&pagination=${data.item_per_page ? data.item_per_page : 10}`,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				commit('MASTER_MAINTENANCE_EMPLOYEE_DATA', res)
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

	/* MAINTENANCE */
	getUnscheduledMaintenance({ commit }, data) {
		// console.log("getUnscheduledMaintenance");
		// console.log(data);
		return new Promise((resolve, reject) => {
			var url = `${process.env.VUE_APP_URL}/api/kka/maintenance/unschedule_maintenance_optional?mill_id=${data.mill_id}&mach_type=${data.mach_type}&mach_id=${data.mach_id}&no_wr=${data.no_wr}&category_maintenance=${data.category_maintenance}&status=${data.status}&description=${data.description}&req_sparepart=${data.req_sparepart}&request_date=${data.request_date}&start_date=${data.start_date}&finish_date=${data.finish_date}&closed_date=${data.closed_date}&requested_by=${data.requested_by}&closed_by=${data.closed_by}&created_by=${data.created_by}&page=${data.page > 1 ? data.page : ''}&pagination=${data.itemsPerPage}`
			console.log(url);
			axios.get(url,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				// console.log("status = " + data.status);
				// console.log(res);
				if(data.status === 1){
					commit('UNSCHEDULE_MAINTENANCE_CREATE', res)
				} else
				if(data.status === 2){
					commit('UNSCHEDULE_MAINTENANCE_MAINTAIN', res)
				} else {
				// if(data.data.status === "3" || data.data.status === "4"){
					commit('UNSCHEDULE_MAINTENANCE_CLOSE', res)
				}
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},
	getUnscheduledMaintenanceAction({ commit }, data) {
		console.log("getUnscheduledMaintenanceAction");
		console.log(data);
		return new Promise((resolve, reject) => {
			// var url = `${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance/?mill_id=${data.mill_id}&mach_type=${data.mach_type}&mach_id=${data.mach_id}&no_wr=${data.no_wr}&wr_action_no=${data.wr_action_no}&status_action=${data.status_action}&shift=${data.shift}&require_sparepart=${data.require_sparepart}&observation=${data.observation}&correction=${data.correction}&remark_action${data.remark_action}=&remark_inspection${data.remark_inpsection}=&start_date=${data.start_date}&finish_date=${data.finish_date}&inspect_date=${data.inspect_date}&duration=${data.duration}&loss_time=${data.loss_time}&downtime=${data.downtime}&action_by=${data.action_by}&inspected_by=${data.inspected_by}&page=${data.page > 1 ? data.page : ''}&pagination=${data.itemPerPage}`
			var url = `${process.env.VUE_APP_URL}${data.url}`
			console.log(data.url);
			axios.get(url,
			{
				headers: {Authorization: `Bearer ${this.getters.isTokenUser}`,},
          	})
			.then(res => {
				// console.log("status = " + data.status);
				// console.log(res);
				if(data.status_action === 1){
					commit('UNSCHEDULE_MAINTENANCE_ACTION_CREATE', res)
				} else {
				// if(data.data.status === "3" || data.data.status === "4"){
					commit('UNSCHEDULE_MAINTENANCE_ACTION_INSPECT', res)
				}
				resolve(res)
			})
			.catch(err => {
				reject(err)
			})
		})
	},

  },
  modules: {
	//   masterMaintenance,
	},
  getters: {
		isUserLoggedIn: state => !!state.user.token,
		isTokenUser: state => state.user.token,
		UsernameUser: state => state.user.username,
		Idemploye: state => state.user.idemployee,
		shift: state => state.user.shift,
		convertToCurrency: state => number => {
			return new Intl.NumberFormat('id-ID').format(number)
		},
		convertToCurrencyUs: state => number => {
			return new Intl.NumberFormat('en-US', {maximumFractionDigits:2}).format(number)
		},
		convertToCurrencyNoFrac: state => number => {
			return new Intl.NumberFormat('en-US', {maximumFractionDigits:0}).format(number)
		},
  }
});
