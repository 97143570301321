import {backendApi} from "../backend-api-sr"

export const orderContractApproval = (() =>{
    const test = (() => {
        return ""
    })

    const showContractApproval = ( async (subPath) => {
        var url = '/api/amerta/order/contract_approval/show'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const showDetailContractApproval = ( async (subPath) => {
        var url = '/api/amerta/order/contract_approval/showDetail'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const updateContractApproval = ( async (subPath, reqBody) => {
        var url = '/api/amerta/order/contract_approval/updateApproval'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    

    return {showContractApproval,showDetailContractApproval,updateContractApproval};

})()