<template>
    <v-container fluid style="margin-bottom:200px">
      <v-row>
        <v-col cols="12">
            <v-breadcrumbs :items="breadcumbs">
                <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                        :to="item.href"
                        class="text-subtitle-2 crumb-item"
                        :disabled="item.disabled"
                        exact
                    >
                        {{ item.text }}
                    </v-breadcrumbs-item>
                </template>
            </v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row>
            <!-- <v-col cols="12">
                <v-card class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="office"
                                    :items="offices"
                                    item-value="office_id"
                                    item-text="office"
                                    label="Office"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>

                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="approve"
                                    :items="approves"
                                    item-value="value"
                                    item-text="descr"
                                    label="Approved"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-autocomplete
                                    solo
                                    clearable
                                    v-model="status"
                                    :items="statuses"
                                    item-value="value"
                                    item-text="status"
                                    label="Status"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_from"
                                        label="Date From"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="date_to"
                                        label="Date to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-12" sm="6" md="2" lg="2">
                                <v-btn class="border-12" style="padding: 20px;"
                                color="info" elevation="2" small @click="getPullData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col> -->
            <v-col cols="12" style="padding-bottom: 100px">
                <v-card v-show="display == 'none' ? false: true" class="p-2" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    dense
                                    :headers="headers"
                                    :items="contracts"
                                    :items-per-page="30"
                                    :footer-props="{
                                        'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                    }"
                                    :loading="$store.state.overlay"
                                    height="425"
                                    fixed-header
                                    :divider="true"
                                    :light="true"
                                    :search="searchPo" 
                                    :item-class="tr_datatable"
                                >    
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Result </h6>
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="searchPo" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.dt_trans`]="{ item }">
                                        {{ (new Date(new Date(item.dt_trans) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                    </template>
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.total) }}
                                    </template>
                                    <!-- <template v-slot:[`item.unit_price`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.amt_ppn) }}
                                    </template>
                                    <template v-slot:[`item.amt_net`]="{ item }">
                                        {{ $store.getters.convertToCurrencyNoFrac(item.amt_net) }}
                                    </template> -->
                                    <template v-slot:[`item.approve1`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <!-- Pengecekan DENGAN Hak Approval -->
                                                <div v-if="approval1 == 'Y'">
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.dt_aprv1 === '1900-01-01 00:00:00.000'" 
                                                    @click="setApprove1(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                         Confirmation
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    v-else-if="item.dt_aprv1 != '1900-01-01 00:00:00.000'"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                                <div v-else>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="red"
                                                    text-color="white"
                                                    v-if="item.dt_aprv1 === '1900-01-01 00:00:00.000'"
                                                    >
                                                         Not Approved
                                                    </v-chip>
                                                    
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    v-else-if="item.dt_aprv1 != '1900-01-01 00:00:00.000'"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div>
                                                <!-- Pengecekan TANPA Hak Approval -->
                                                <!-- <div>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="primary"
                                                    v-if="item.dt_aprv1 === '1900-01-01 00:00:00.000'" 
                                                    @click="setApprove1(item)" v-on="on"
                                                    >
                                                        <v-icon center> 
                                                        mdi-check-circle
                                                        </v-icon>
                                                         Confirmation
                                                    </v-chip>
                                                    <v-chip
                                                    class="ma-2"
                                                    color="success"
                                                    v-else-if="item.dt_aprv1 != '1900-01-01 00:00:00.000'"
                                                    >
                                                    {{ (new Date(new Date(item.dt_aprv1) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10) }}
                                                    </v-chip>
                                                </div> -->
                                            </template>
                                            <span>Confirm</span>
                                        </v-tooltip>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-dialog v-model="dialog" max-width="1000px">                
                    <v-card>
                        <v-card-title class="text-center">
                            <v-row>
                                <v-col cols="12">
                                    <div class="d-flex">
                                        <div class="ml-5">
                                            Detail Order
                                        </div>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            class="mx-2"
                                            fab
                                            x-small
                                            color="#e83e8c;"
                                            @click="close"
                                        >
                                            <v-icon dark>
                                                mdi-close
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                             <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <v-data-table
                                            dense
                                            :headers="header_po_items"
                                            :items="contract_details"
                                            :items-per-page="30"
                                            :footer-props="{
                                                'items-per-page-options': [10, 20, 30, 40, 50, -1]
                                            }"
                                            :loading="$store.state.overlay"
                                            height="425"
                                            fixed-header
                                            :divider="true"
                                            :light="true"
                                            :search="search_item" 
                                            :item-class="tr_datatable"
                                        >    
                                            <template v-slot:top>
                                                <v-toolbar flat color="white" class="mt-2 mb-2">
                                                    <div class="d-flex w-100">
                                                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="">mdi-table</v-icon> Item </h6>
                                                        <v-spacer></v-spacer>
                                                        <v-text-field solo style="max-width: 350px;" class="border-12 mr-3" v-model="search_item" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                                    </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.unit_price`]="{ item }">
                                                {{ $store.getters.convertToCurrencyNoFrac(item.unit_price) }}
                                            </template>
                                            <template v-slot:[`item.tr_qty`]="{ item }">
                                                {{ $store.getters.convertToCurrencyNoFrac(item.tr_qty) }}
                                            </template>
                                            <!-- <template v-slot:[`item.durasi`]="{ item }">
                                                {{ $store.getters.convertToCurrencyUs(item.durasi) }}
                                            </template> -->
                                            <!-- <template v-slot:[`item.bunga`]="{ item }">
                                                {{ $store.getters.convertToCurrencyNoFrac(item.bunga) }}
                                            </template> -->
                                        </v-data-table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog
                v-model="dialogAgree"
                persistent
                max-width="500"
                >
                    <v-card>
                        <v-card-title class="text-h5">
                        Are you sure you approve this Contract?
                        </v-card-title>
                        <v-card-text>If you are sure click Agree, if not click Cancel.</v-card-text>
                        <v-card-actions>
                        <v-btn
                        class="pull-left"
                            color="green darken-1"
                            text
                            @click="dialogAgree = false"
                        >
                            Cancel
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                            class="ma-2"
                            :loading="loading_reject"
                            :disabled="loading_reject"
                            color="red"
                            text-color="white"
                            @click="submitReject()"
                            >
                            <v-icon light>mdi-close-circle-outline</v-icon>

                             Reject
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn> -->
                        <v-btn
                            class="ma-2"
                            :loading="loading4"
                            :disabled="loading4"
                            color="green darken-1"
                            text-color="white"
                            @click="submitApprove1()"
                            >
                            <v-icon>mdi-check-circle-outline</v-icon>
                             Agree
                            <template v-slot:loader>
                                <span class="custom-loader">
                                <v-icon light>mdi-cached</v-icon>
                                </span>
                            </template>
                        </v-btn>
                        </v-card-actions>
                    </v-card>
                    
                </v-dialog>
                
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
      </v-row>
    </v-container>
</template>
<script>

import { amertaApi } from "../../../backend-api/amerta/amertaApi";
import { orderContractApproval } from "../../../backend-api/amerta/orderContractApproval";

export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Amerta',
                disabled: false,
                href: '/admin/amerta',
                },
                {
                text: 'Order',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Contract Approval',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading: false,
            search_item: '',
            offices: [],
            office: '',
            departments: [],
            department: '',
            approves: [
                {
                    descr: 'All',
                    value: 'all'
                },
                {
                    descr: 'Not Approved 1',
                    value: 'approve1'
                },  
                {
                    descr: 'Rejected',
                    value: 'reject'
                }
            ],
            approve: 'all',
            status: '',
            statuses: [
                {
                    status: 'OPEN',
                    value: 'O'
                },
                {
                    status: 'CLOSE',
                    value: 'C'
                }
            ],
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            display: 'block',
            purchases: [],
            headers: [
                { text: '',  value: 'actions', sortable: false,width: 50 },
                {
                    text: 'Doc ID',
                    align: 'start',
                    value: 'doc_id',
                    width: 100
                },
                { text: 'Customer 1', value: 'nama1', width: 100 },
                // { text: 'Customer 2', value: 'nama2', sortable: false,width: 100 },
                { text: 'Date', value: 'dt_trans', align:'right', sortable: false,width: 100 },
                { text: 'Total', value: 'total', align:'right', sortable: false,width: 100 },
                { text: 'Approve',  value: 'approve1', align: 'center', sortable: false,width: 100 },
            ],
            contracts: [],
            totalItems: 10,
            options: {},
            loading2: false,
            loading_reject: false,
            search_detail: '',
            header_po_items:[
                {
                    text: 'Doc ID',
                    align: 'start',
                    sortable: true,
                    value: 'doc_id',
                },
                { text: 'Description', value: 'description' },
                { text: 'Unit Price', value: 'unit_price' },
                { text: 'jumlah', value: 'tr_qty' },
                { text: 'ID Paket', value: 'paket_id' },
                { text: 'Durasi', value: 'durasi' }
            ],
            contract_details: [],
            po_item: '',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            totalItem_po_items: 10,
            dialog: false,
            data_var: {
                entity_id : 'AMERTA',
                appl_id : 'WEBAMERTA'
            },
            user_approve1: '',
            user_approve2: '',
            dialogAgree: false,
            dialogAgree2: false,
            dialogDisagree2: false,
            dialogDisagree: false,
            pagination: 1,
            pagination_item: 1,
            sortBy: 'desc',
            sortName: '',
            searchText: false,
            searchPo: '',
            var_value_getEnv: '',
            approval1: '',
            EnvConfs: []
        }
    },
    async mounted(){
        this.getEnvConf()
        this.$store.dispatch('setOverlay', true)  
        // this.getOffice()
        // this.getPullData()
        this.$store.dispatch('setOverlay', false)  
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        async getEnvConf(){
            this.EnvConfs = []
            this.$store.dispatch('setOverlay', true)
            
            let $url = `?idemployee=${this.$store.getters.UsernameUser}`
            var respData = await amertaApi.getEnvConf($url, null, false, false, false)
            if (respData.status === 301) {
                this.EnvConfs = respData.data.data
                this.$store.dispatch('setOverlay', false)
                this.approval1 = 'Y'
                this.getPullData()
            }else{
                this.approval1 = 'N'
                this.$store.dispatch('setOverlay', false)
                this.getPullData()
            }
        },
        search(){
            this.pagination = 1
            this.searchText = true
            this.getPullData()
        },
        close() {
            this.dialog = false
        },
        showItem(item){
            console.log(item);
            this.po_item = item
            this.po_items = []
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(item)
        },
        async getPullDataDetail(item){
            this.contract_details = []
            this.$store.dispatch('setOverlay', true)
            
            let $url = `?doc_id=${item.doc_id}`
            var respData = await orderContractApproval.showDetailContractApproval($url, null, false, false, false)
            if (respData.status === 200) {
                // console.log(respData)
                // console.log(respData.data)
                this.contract_details = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else{
               this.$store.dispatch('setOverlay', false)
            }
            console.log(respData.data)
        },

        sortByFunc(sortBy){
            console.log(this.sortBy, sortBy);
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.getPullData()
        },
        async getPullData(){
            this.contracts = []
            this.$store.dispatch('setOverlay', true)
            
            
            var respData = await orderContractApproval.showContractApproval(``, null, false, false, false)
            if (respData.status === 200) {
                this.contracts = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else{
               this.$store.dispatch('setOverlay', false)
            }

        },
        async getOffice(){
            this.offices = []
            this.$store.dispatch('setOverlay', true)
            
            var respData = await amertaApi.getOffice(``, null, false, false, false)
            if (respData.status === 200) {
                this.offices = respData.data.data
                this.$store.dispatch('setOverlay', false)
            } else{
               this.$store.dispatch('setOverlay', false)
            }
        },
        setApprove1(item){
            this.dialogAgree = true
            this.doc_id = item
        },
        async submitApprove1(){
            this.loading4 = true
            this.$store.dispatch('setOverlay', true)
            this.dialog = false

            var reqBody = {
                'doc_id'    : this.doc_id.doc_id ,
                'approve'        : '1' ,
            }

            const respData = await orderContractApproval.updateContractApproval('', reqBody, false, false, false)

            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Successfully Create Data',
                    visible: true
                };

                this.$store.dispatch('setOverlay', false)
                this.dialog = false
                this.loading4 = false
                this.dialogAgree = false
                this.getPullData()

            } else{
                this.$store.dispatch('setOverlay', false)
                this.dialog = true

                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data,
                    visible: true
                };
            }
        },
        
    },
    watch: {
    }
}
</script>