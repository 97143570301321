import {backendApi} from "../backend-api-sr"

export const amertaApi = (() =>{
    const test = (() => {
        return ""
    })

    const getEnvConf = ( async (subPath) => {
        var url = '/api/amerta/master/getEnvConfAprv'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })
    const getOffice = ( async (subPath) => {
        var url = '/api/amerta/master/office'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })    

    return {getEnvConf, getOffice};

})()