import {backendApi} from "../backend-api-sr"

export const financePaymentApproval = (() =>{
    const test = (() => {
        return ""
    })

    const showPaymentApproval = ( async (subPath) => {
        var url = '/api/amerta/finance/payment_approval/show'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })


    const updatePaymentApproval = ( async (subPath, reqBody) => {
        var url = '/api/amerta/finance/payment_approval/updateApproval'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const sendNotifAprvWA = ( async (subPath, reqBody) => {
        var url = '/api/WhatsAppGateway_1'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    
    
    return {showPaymentApproval,updatePaymentApproval,sendNotifAprvWA};

})()