import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../components/Layouts/Default_Dashboard.vue";
import Children from "../layouts/Children.vue";
import Master from "../layouts/Master.vue";
import store from "../store/index.js";
import Home from "../views/Home.vue";
import Overview from "../views/Overview.vue";
import Profile from "../views/Profile.vue";
import PageNotFound from "../views/PageNotFound.vue";

//Amerta
import OverviewAmerta from "../views/amerta/Overview.vue";
import ContractApprovalAmerta from "../views/amerta/order/ContractApproval.vue";
import PaymentApprovalAmerta from "../views/amerta/finance/PaymentApproval.vue";

Vue.use(VueRouter);

function checkAuth(to, from, resolve, reject) {
  store.dispatch('setOverlay', true)  
  axios.get(`${process.env.VUE_APP_URL}/api/cek_token`, { 
      headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
  }).then(response => {
      // Token is valid, so continue
      store.dispatch('setOverlay', false)  
      resolve();
  }).catch(error => {
      // There was an error so redirect
      router.push({ path: "/" });
  })
}

function checkAuthAccessMenuUser(to, from, resolve, reject) {
  store.dispatch('setOverlay', true)  

  axios.get(`${process.env.VUE_APP_URL}/api/cek_token_menu?route=${to.fullPath}`, { 
      headers: { Authorization: `Bearer ${store.getters.isTokenUser}` } 
  }).then(response => {

      if (response.data === true) {
        store.dispatch('setOverlay', false)  
        resolve();
      } else {
        store.dispatch('setOverlay', false)  
        router.push({ path: "/PageNotFound" });
      }
      // Token is valid, so continue
  }).catch(error => {
      // There was an error so redirect
      store.dispatch('setOverlay', false)  
      router.push({ path: "/PageNotFound" });
  })
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/password/reset",
    name: "ForgetPassword",
    component: () =>
      import("../views/ForgetPassword.vue"),
  },
  {
    path: "/reset/password/:link",
    name: "ResetPassword",
    component: () =>
      import("../views/ResetPassword.vue"),
  },
  {
    path: "/absencePermit",
    name: "absencePermit",
    component: () =>
      import("../views/absencePermit.vue"),
  },
  // {
  //   path: "/admin/undermaintenance",
  //   name: "UnderMaintenance",
  //   component: () =>
  //     import("../views/UnderMaintenance.vue"),
  // },
  {
    path: "/admin",
    component: DashboardLayout,
    redirect: "/admin/dashboard",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'dashboard',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile
      },
    ]
  },

  { path: "*", 
    component: DashboardLayout,
    redirect: "/PageNotFound",
    beforeEnter: checkAuth,
    children: [
      {
        path: 'PageNotFound',
        name: 'PageNotFound',
        component: PageNotFound
      }
    ]
  },

  // Amerta
  {
    path: "/admin/amerta",
    component: Master,
    redirect: "/admin/amerta/dashboard",
    beforeEnter: checkAuth,
    name: "AMERTA",
    children: [
      {
        path: 'dashboard',
        name: 'OverviewAmertas',
        component: OverviewAmerta
      },
      {
        path: "dashboard",
        component: Children,
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'OverviewAmerta',
            name: 'Dashboards',
            component: () => import("../views/amerta/dashboard/OverviewAmerta"),
          },
        ]
      },
      {
        path: "order",
        component: Children,
        redirect: "/order/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'ContractApproval',
            name: 'ContractApprovalAmerta',
            component: ContractApprovalAmerta
          },
        ]
      },
      {
        path: "finance",
        component: Children,
        redirect: "/finance/dashboard",
        beforeEnter: checkAuth,
        children: [
          {
            path: 'PaymentApproval',
            name: 'PaymentApprovalAmerta',
            component: PaymentApprovalAmerta
          },
        ]
      }
    ]
  },
  
  //Griya Hotel
  {
    path: "/admin/gk",
    component: Master,
    redirect: "/admin/gk/dashboard",
    beforeEnter: checkAuth,
    name: "GK",
    props:true,
    children:[
      // {
      //   path: 'dashboard',
      //   name: 'OverviewGriya',
      //   component: () => import("../views/griya/OverviewGriya.vue")
        
      // },
      

      {
        path: "dashboard",
        component: Children,
        children: [
          {
            path: '',
            name: 'OverviewGriya',
            component: () => import("../views/griya/OverviewGriya.vue")
            
          },
          {
            path: 'Overviewgk',
            name: 'PurchaseOrderGriyas',
            component: () => import("../views/griya/dashboard/dashboardgk.vue"),
          },
         
        ]
      },  
      {
        path: "approval",
        component: Children,
        redirect: "/admin/gk/approval",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'purchase-order',
            name: 'PurchaseOrderGriya',
            component: () => import("../views/griya/PurchaseOrder.vue"),
          },
          {
            path: 'purchase-request',
            name: 'PurchaseRequestGriya',
            component: () => import("../views/griya/PurchaseRequest.vue"),
          },
        ]
      },
      {
        path: "Information",
        component: Children,
        redirect: "/admin/gk/journal",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
          {
            path: 'jurnal',
            name: 'Jurnal-griya',
            component: () => import("../views/griya/information/JournalYear"),
          },
          {
            path: 'mutasi',
            name: 'mutasi-finance',
            component: () => import("../views/griya/information/Mutasi"),
          },
          {
            path: 'Income',
            name: 'Income & Cost List',
            component: () => import("../views/griya/information/Income"),
          },
          {
            path: 'Occupancy',
            name: 'Occupancys',
            component: () => import("../views/griya/information/Occupancy"),
          },
        ]
      },
      {
        path: "SalesActivity",
        component: Children,
        redirect: "/admin/gk/SalesActivity",
        beforeEnter: checkAuthAccessMenuUser,
        children: [
            {
              path: 'CustomerVisit',
              name: 'CustomerVisits',
              component: () => import("../views/griya/salesactivity/CustomerVisit.vue"),
            },
            {
              path: 'TodayVisit',
              name: 'TodayVisits',
              component: () => import("../views/griya/salesactivity/TodayVisit.vue"),
            },
            {
              path: 'VisitReport',
              name: 'VisitReports',
              component: () => import("../views/griya/salesactivity/VisitReport.vue"),
            },
          ]
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let programmatic = false
;(['push', 'replace', 'go', 'back', 'forward']).forEach(methodName => {
  const method = router[methodName]
  router[methodName] = (...args) => {
    programmatic = true
    method.apply(router, args)
  }
})

router.beforeEach((to, from, next) => {
  // name is null for initial load or page reload
  if (from.name === null || programmatic) {
    // triggered bu router.push/go/... call
    // route as usual
    next()
  } else {
    // triggered by user back/forward 
    // do not route
    next(false)
  }
  programmatic = false // clear flag
})

export default router;