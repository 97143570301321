export class User {

    constructor(data) {
        this.data = data
    }

    name() {
        return this.data.name1
    }

    avatar() {
        return this.data.avatar
    }

}